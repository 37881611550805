import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchUsers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/User`, queryParams)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchUser(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_BASE_URL}/api/User/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/User/create`, userData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${process.env.VUE_APP_BASE_URL}/api/User`, userData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addPhoto(ctx, { id, base64 }) {
            var FormData = require('form-data');
            var data = new FormData();
            data.append('', base64);
            return new Promise((resolve, reject) => {
                axios
                    .post(`${process.env.VUE_APP_BASE_URL}/api/User/update-avatar/${id}`, data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        delete(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${process.env.VUE_APP_BASE_URL}/api/User/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deletePic(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_BASE_URL}/api/User/remove-avatar/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        resetPassword(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_BASE_URL}/api/User/reset-password/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}